<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>审核</a-breadcrumb-item>
              <a-breadcrumb-item>审核中心</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div v-if="visible">
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="!visible"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px; margin-top: 3px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flow-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-date-picker
                  style="width: 178px"
                  @change="onchangess"
                  v-if="i == '操作日期'"
                  v-model="time"
                />
                <a-input
                  v-else
                  style="width: 178px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div>
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="historieslist"
              :rowClassName="rowClassName"
            >
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
              <span slot="params" slot-scope="text">
                <a-tooltip>
                  <template slot="title">
                    {{ text }}
                  </template>
                  {{ text | text }}
                </a-tooltip>
              </span>
              <span slot="path" slot-scope="text">
                <a-tooltip>
                  <template slot="title">
                    {{ text }}
                  </template>
                  {{ text | texts }}
                </a-tooltip>
              </span>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>基础信息</span>
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-row"></div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '操作人',
    dataIndex: 'customerUserName',
  },
  {
    title: '操作设备',
    dataIndex: 'device',
  },
  {
    title: '请求方法',
    dataIndex: 'method',
  },
  {
    title: 'IP',
    dataIndex: 'ip',
  },
  {
    title: '操作模块',
    dataIndex: 'module',
  },
  {
    title: '请求参数',
    dataIndex: 'params',
    scopedSlots: { customRender: 'params' },
  },
  {
    title: '操作路径',
    dataIndex: 'path',
    scopedSlots: { customRender: 'path' },
  },
  {
    title: '操作结果',
    dataIndex: 'resultMsg',
  },
  {
    title: '操作时间',
    dataIndex: 'time',
    scopedSlots: { customRender: 'time' },
  },
]
import moment from 'moment'
import http from '../../../http'
export default {
  data() {
    return {
      moment,
      columns,
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      params: {
        current: 1,
        pageSize: 10,
        customer_user_name: {
          value: '',
          op: '%like%',
        },
        device: {
          value: '',
          op: '%like%',
        },
        method: {
          value: '',
          op: '=',
        },
        ip: {
          value: '',
          op: '%like%',
        },
        module: {
          value: '',
          op: '%like%',
        },
        result_msg: {
          value: '',
          op: '%like%',
        },
        time: {
          value: '',
          op: '%like%',
        },
      },
      time: '',
      total: 0,
      textlist: [
        '操作人',
        '操作设备',
        '请求方法',
        'IP',
        '模块',
        '操作结果',
        '操作日期',
      ],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),

      historieslist: [],
      form: {
        id: '',
        auditNote: '',
        auditAttach: '',
        operate: '',
      },
    }
  },
  filters: {
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[0] + ' ' + times[1]
      }
    },
    text(value) {
      if (!value) return ''
      if (value.length > 20) {
        return value.slice(0, 20) + '...'
      }
      return value
    },
    texts(value) {
      if (!value) return ''
      if (value.length > 30) {
        return value.slice(0, 30) + '...'
      }
      return value
    },
  },
  methods: {
    handleSearch(e) {
      this.params.current = 1
      e.preventDefault()
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['customer_user_name'].value = values.操作人
        this.params.device.value = values.操作设备
        this.params.method.value = values.请求方法
        this.params['ip'].value = values.IP
        this.params['module'].value = values.模块
        this.params['result_msg'].value = values.操作结果
      })
      this.getoperationHistory()
    },
    handleReset() {
      this.time = ''
      this.params.time.value = ''
      this.fom.resetFields()
    },
    onchangess(e) {
      this.params.time.value = moment(e).format('YYYY-MM-DD')
    },
    onpagesize(e) {
      this.params.current = e
      setTimeout(() => {
        this.getoperationHistory()
      }, 100)
    },
    async getoperationHistory() {
      try {
        const res = await http.getoperationHistory(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.historieslist = data.histories.records
          this.total = data.histories.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },

    timer() {
      return setTimeout(() => {
        this.getoperationHistory()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getoperationHistory()
  },
  computed: {},
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.form-item {
  .ant-form-item {
    // height: 60px;
    margin-bottom: 0;
  }
}
.ant-form-item {
  line-height: 2.5;
  margin: 0;
}
::v-deep .ant-form-item-label {
  line-height: 20px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 600;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.ant--input {
  width: 50%;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 39px;
}
</style>
