var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{staticStyle:{"padding":"0px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{staticStyle:{"background-color":"#fff","line-height":"60px","margin":"0","width":"100%","height":"60px"}},[_c('div',{staticClass:"Enter-top"},[_c('div',[_c('div',[_c('a-breadcrumb',{staticStyle:{"margin":"18px 0"}},[_c('a-breadcrumb-item',[_vm._v("审核")]),_c('a-breadcrumb-item',[_vm._v("审核中心")])],1)],1)]),(_vm.visible)?_c('div',[_c('a-icon',{staticStyle:{"width":"30px","margin-right":"5px","cursor":"pointer","font-size":"25px"},attrs:{"type":"rollback"},on:{"click":() => {
              this.Id = ''
              this.visible = false
            }}})],1):_vm._e()])]),(!_vm.visible)?_c('a-layout',{staticStyle:{"padding":"24px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{staticStyle:{"background-color":"#fff","line-height":"60px","margin":"0","width":"100%"}},[_c('a-form',{staticClass:"ant-advanced-search-form",staticStyle:{"padding":"0 24px","margin-top":"3px"},attrs:{"form":_vm.fom},on:{"submit":_vm.handleSearch}},[_c('a-row',{staticClass:"flow-d-row",staticStyle:{"flex-wrap":"wrap"},attrs:{"gutter":24}},[_vm._l((_vm.textlist),function(i,index){return _c('a-col',{key:index},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":i}},[(i == '操作日期')?_c('a-date-picker',{staticStyle:{"width":"178px"},on:{"change":_vm.onchangess},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `${i}`,
                  {
                    rules: [],
                  },
                ]),expression:"[\n                  `${i}`,\n                  {\n                    rules: [],\n                  },\n                ]"}],staticStyle:{"width":"178px"},attrs:{"placeholder":'请输入' + i}})],1)],1)}),_c('a-col',{staticClass:"a-col-top"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 搜索 ")]),_c('a-button',{style:({ marginLeft: '8px' }),on:{"click":_vm.handleReset}},[_vm._v(" 清空 ")])],1)],2)],1)],1),_c('a-layout-content',{style:({
        background: '#fff',
        padding: '24px',
        margin: 0,
        overflow: _vm.auto,
      })},[_c('div',{staticClass:"search-wrap"},[_c('div',[_c('a-table',{attrs:{"row-selection":_vm.rowSelection,"rowKey":"id","pagination":false,"columns":_vm.columns,"data-source":_vm.historieslist,"rowClassName":_vm.rowClassName},scopedSlots:_vm._u([{key:"time",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm._f("time")(text))+" ")])}},{key:"params",fn:function(text){return _c('span',{},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(text)+" ")]),_vm._v(" "+_vm._s(_vm._f("text")(text))+" ")],2)],1)}},{key:"path",fn:function(text){return _c('span',{},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(text)+" ")]),_vm._v(" "+_vm._s(_vm._f("texts")(text))+" ")],2)],1)}}],null,false,438891664)}),_c('a-pagination',{attrs:{"page-size":_vm.params.pageSize,"show-quick-jumper":"","default-current":2,"total":_vm.total},on:{"change":_vm.onpagesize},model:{value:(_vm.params.current),callback:function ($$v) {_vm.$set(_vm.params, "current", $$v)},expression:"params.current"}})],1)])])],1):_c('a-layout',{staticStyle:{"padding":"24px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{style:({
        background: '#fff',
        margin: 0,
      })},[_c('div',{staticClass:"content-title"},[_c('span',[_vm._v("基础信息")])]),_c('div',{staticClass:"content-body flex-d-row",staticStyle:{"flex-wrap":"wrap"}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }